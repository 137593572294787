<template>
  <div>
    <Header />
    <Mobileheader />

    <div class="mediumbreak"></div>
    <h1 class="contact_header" data-aos="fade-up" data-aos-duration="3000">
      <b>CONTACT US</b>
    </h1>

    <h2 class="contact_subheader" data-aos="fade-up" data-aos-duration="3000">
      Drop us a line!
    </h2>

    <!-- Contact v1 -->
    <div class="contact_form" data-aos="fade-up" data-aos-duration="3000">
      <b-container>
        <b-card class="mb-0">
          <!-- form -->
          <validation-observer ref="contactForm" #default="{ invalid }">
            <b-form class="auth-contact-form mt-2" @submit.prevent>
              <!--name -->
              <b-col cols="12">
                <b-form-group label="Name" label-for="contact-username">
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="contact-username"
                      v-model="form.name"
                      name="contact-username"
                      :state="errors.length > 0 ? false : null"
                      placeholder="johndoe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- email -->

              <b-col cols="12">
                <b-form-group label="Email" label-for="contact-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="contact-email"
                      v-model="form.email"
                      name="contact-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Contact Number -->
              <b-col cols="12">
                <b-form-group>
                  <label>Contact number</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|digits:10|integer"
                    name="Mobile"
                  >
                    <b-form-input
                      v-model="form.mobile"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Mobile Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Massage -->
              <b-col cols="12">
                <b-form-group>
                  <label>Massage</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Massage"
                  >
                    <b-form-textarea
                      v-model="form.massage"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Massage"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Button -->
              <b-col cols="6" class="contact_form_button">
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  @click="addContact()"
                  :disabled="invalid"
                >
                  SUBMIT
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-card>
      </b-container>
      <!-- /contact v1 -->
    </div>
    <div class="mediumbreak"></div>

    <b-container data-aos="fade-up" data-aos-duration="3000">
      <b-row>
        <b-col md="4" lg="4">
          <div class="contact_map">
            <iframe
              class="contact_iframe"
              height="350"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.9234574425805!2d149.1842616151012!3d-35.33272218027699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164c2fa92eb631%3A0x32f71c27344e27d3!2s8%2F45%20Tennant%20St%2C%20Fyshwick%20ACT%202609%2C%20Australia!5e0!3m2!1sen!2slk!4v1660217365730!5m2!1sen!2slk"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            <h5 class="contact_text">Head Office</h5>
            <h6 class="contact_map_sub_heading">Address</h6>
            <p class="contact_para">8/45 Tennant Street Fyshwick , 2609</p>
            <h6 class="contact_map_sub_heading">Mobile</h6>
            <p class="contact_para">0422 686 618 or 0478 360 601</p>
            <h6 class="contact_map_sub_heading">E-mail</h6>
            <p class="contact_para">office@sindhizgroup.com.au</p>
          </div>
        </b-col>
        <b-col md="4" lg="4">
          <div class="contact_map">
            <iframe
              class="contact_iframe"
              height="350"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.9234574425805!2d149.1842616151012!3d-35.33272218027699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164c2fa92eb631%3A0x32f71c27344e27d3!2s8%2F45%20Tennant%20St%2C%20Fyshwick%20ACT%202609%2C%20Australia!5e0!3m2!1sen!2slk!4v1660217365730!5m2!1sen!2slk"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            <h5 class="contact_text">Head Office</h5>
            <h6 class="contact_map_sub_heading">Address</h6>
            <p class="contact_para">8/45 Tennant Street Fyshwick , 2609</p>
            <h6 class="contact_map_sub_heading">Mobile</h6>
            <p class="contact_para">0422 686 618 or 0478 360 601</p>
            <h6 class="contact_map_sub_heading">E-mail</h6>
            <p class="contact_para">office@sindhizgroup.com.au</p>
          </div>
        </b-col>
        <b-col md="4" lg="4">
          <div class="contact_map">
            <iframe
              class="contact_iframe"
              height="350"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.9234574425805!2d149.1842616151012!3d-35.33272218027699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b164c2fa92eb631%3A0x32f71c27344e27d3!2s8%2F45%20Tennant%20St%2C%20Fyshwick%20ACT%202609%2C%20Australia!5e0!3m2!1sen!2slk!4v1660217365730!5m2!1sen!2slk"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>

            <h5 class="contact_text">Head Office</h5>
            <h6 class="contact_map_sub_heading">Address</h6>
            <p class="contact_para">8/45 Tennant Street Fyshwick , 2609</p>
            <h6 class="contact_map_sub_heading">Mobile</h6>
            <p class="contact_para">0422 686 618 or 0478 360 601</p>
            <h6 class="contact_map_sub_heading">E-mail</h6>
            <p class="contact_para">office@sindhizgroup.com.au</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="largebreak"></div>
    <Footer />
  </div>
</template>
<script>
import Header from "@/views/components/header.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "@/views/components/Footer.vue";
import ConatcsApi from "@/Api/Modules/contact";
import {
  BContainer,
  BRow,
  BCard,
  BCol,
  BImg,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

import Mobileheader from "@/views/components/mobileheader.vue";
import Ripple from "vue-ripple-directive";
export default {
  data() {
    return {
      form: {},

      // validations

      required,
      email,
    };
  },
  components: {
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    Footer,
    BCard,
    Header,
    Mobileheader,
    BContainer,
    BImg,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormText,
    BFormDatalist,
  },
  directives: {
    Ripple,
  },
  created() {
    AOS.init();
  },
  methods: {
    async addContact() {
      if (await this.$refs.contactForm.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });

        await ConatcsApi.Add(this.form)
          .then(({ response }) => {
            this.$vs.loading.close();
          })
          .catch(({ response }) => {
            this.$vs.loading.close();
          });
      }

      setTimeout(() => {
        this.form = {};
      }, 7000);
    },
  },
};
</script>
